import React from "react";
import PropTypes from "prop-types";

import ValidatorForm from "components/forms/validator-form";
import TextField from "components/forms/text-field";
import Separator from "components/template/separator";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import Link from "@mui/material/Link";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import {Row, RowItem} from "components/theme";


import * as Style from "./theme";

/**
 * Componente login form
 */
export default class LoginForm extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        onShowPassword: PropTypes.func,
        loading: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            showPassword: false
        };
    }

    handleSubmit = () => {
        const {onSubmit} = this.props;
        if (onSubmit) {
            onSubmit(this.state);
        }
    };

    /** Cambiamento elementi form */
    handleChange = name => value => {
        this.setState({
            [name]: value
        });
    };

    /** Visualizza/nasconde la passowrd */
    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        });
    };

    render() {
        const {loading} = this.props;
        let loader = null;
        if (loading) {
            loader = (
                <Style.LoaderWrap>
                    <CircularProgress/>
                </Style.LoaderWrap>);
        }
        return (
            <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
                <TextField
                    name={"user"}
                    keyInput={"user"}
                    value={this.state.username}
                    onChange={this.handleChange("username")}
                    label="User"
                    variant="outlined"
                    fullWidth
                    validators={["required"]}
                    errorMessages={"inserisci un utente valido"}
                    endAdornment={
                        <InputAdornment position="end">
                            <Style.AccountIcon/>
                        </InputAdornment>
                    }

                />
                <Separator size={2}/>
                <TextField
                    name={"password"}
                    keyInput={"password"}
                    onChange={this.handleChange("password")}
                    value={this.state.password}
                    label="Password"
                    type={this.state.showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    variant={"outlined"}
                    validators={["required"]}
                    errorMessages={"inserisci una password valida"}
                    fullWidth
                    endAdornment={(
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickShowPassword}
                            >
                                {this.state.showPassword ? (
                                    <VisibilityOff/>
                                ) : (
                                    <Visibility/>
                                )}
                            </IconButton>
                        </InputAdornment>
                    )}
                />
                <Separator size={4}/>
                <Row>
                    <RowItem fill={1} textAlign={"left"}>
                        <Style.ForgotLink>
                            <Link href={"#"} onClick={this.props.onShowPassword}>Hai dimenticato la password?</Link>
                        </Style.ForgotLink>
                    </RowItem>
                    <RowItem>
                        <Button variant="contained" color="primary" type="submit" disableElevation>
                            Login
                        </Button>
                    </RowItem>
                </Row>
                {loader}
            </ValidatorForm>
        );
    }
}
