import { lazy } from "react";


function load(code){
    switch (code) {
        case "bank-transaction/list":
            return lazy(() => import("views/bank-transaction/list"));
        default:
            return null;
    }
}
export default load;
