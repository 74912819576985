export const ACTION_ADD_NOTICES = "ADD_NOTICES";
export const ACTION_REMOVE_NOTICES = "REMOVE_NOTICES";

export const TYPE_ERROR = "error";
export const TYPE_SUCCESS = "success";
export const TYPE_WARNING = "warning";
export const TYPE_INFO = "info";

let noticeIds = 0;

export function addNotice(type, message) {
    return {
        type: ACTION_ADD_NOTICES,
        notice: {
            id: ++noticeIds,
            type,
            message
        }
    };
}

export function removeNotice(id) {
    return {
        type: ACTION_REMOVE_NOTICES,
        id
    };
}
