import * as objects from "store/actions/collection";

import { createTransform } from "redux-persist";

// Filtro salvataggio stato lista oggetti
export const collectionStateFilter = createTransform(
    state => {
        if (state) {
            const fs = {};
            Object.keys(state).forEach(key => {
                if (state[key]) {
                    fs[key] = {
                        filters: state[key].filters,
                        pagination: state[key].pagination,
                        order: state[key].order
                    }
                }
            });
            return fs;
        }
        return state
    },
    state => state,
    {
        whitelist: "collection"
    }
);


const initialState = {};

function appReducer(state = initialState, action) {
    switch (action.type) {

        case objects.ACTION_LOAD_LIST_OBJECTS:
            return {
                ...state,
                [action.code]: {
                    ...state[action.code],
                    fields: action.fields,
                    filters: action.filters,
                    pagination: action.pagination,
                    order: action.order,
                    loading: true,
                    error: false,
                    messageError: null,
                    items: null
                }
            };

        case objects.ACTION_LOAD_LIST_OBJECTS_SUCCESS:
            return {
                ...state,
                [action.code]: {
                    ...state[action.code],
                    loading: false,
                    data: action.data,
                }
            };

        case objects.ACTION_LOAD_LIST_OBJECTS_ERROR:
            return {
                ...state,
                [action.code]: {
                    ...state[action.code],
                    fields: [],
                    filters: [],
                    pagination: [],
                    order: null,
                    loading: false,
                    error: true,
                    messageError: action.error,
                    data: null
                }
            };
        case objects.ACTION_CLEAR_LIST:
            const ns = {};
            Object.keys(state).forEach(key => {
                if (key === action.code) {
                    return;
                }
                ns[key] = state[key];
            });
            return ns;
        default:
            return state;
    }
}

export default appReducer;
