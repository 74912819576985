/**
 * Gestione api verso webserver
 */
import axios from "axios";
import FormData from "form-data";
import { END_POINT_API } from "lib/config";
import { logOut } from "store/actions/auth";
import {isEmpty} from "./utils";


class ApiException {
    code = "";
    message = "";
    constructor(code, message) {
        this.code = code;
        this.message = message;
    }
}

/** Redux store */
let store = null;


/**
 * Parserizza la risposta del server
 * @param {any} result
 */
const parseReponse = (result) => {

    if (result == null) {
        throw new ApiException("server_error", "Risposta server non valida");
    }

    const { status } = result.data;
    if (status === "success") {
        return result.data.data;
    }

    const { message, error } = result.data;
    if (error === "invalid_token" && store !== null) {
        store.dispatch(logOut());
    }
    throw new ApiException("response_error", message);
}

const parseErrorResponse = (response) =>{
    if(isEmpty(response.data)){
        throw new ApiException("server_error", "Errore connessione al server");
    }
    const { message, error } = response.data;
    if (error === "invalid_token" && store !== null) {
        store.dispatch(logOut());
    }
    throw new ApiException("response_error", message);
}

/**
 * Esegue una chiamata generica al server
 * @param {*} method
 * @param {*} path
 * @param {*} data
 */
const execute = async (method, path, data) => {
    const url = `${END_POINT_API}${path}`;
    const axiosMethod = axios[method];
    if (!axiosMethod) {
        throw new ApiException("not_supported", "Metodo api richiesto non è supportato");
    }

    let headers = {};
    // aggiunge il token di autenticazione
    let state = store.getState();
    if (state.auth.user && state.auth.user.token) {
        headers['X-Authorization'] = state.auth.user.token;
    }
    if (data) {
        headers['Content-Type'] = 'application/json';
    }

    let result = null;
    try {
        const request = {
            method: method,
            url: url,
            headers: headers,
        };
        request[method === "get" ? "params" : "data"] = data;
        result = await axios(request);
    }
    catch (e) {
        parseErrorResponse(e.response);

    }
    return parseReponse(result);
}

/**
 * Upload di un file nel server
 * @param {*} path
 * @param {*} data
 * @param {*} progressHandle
 */
const uploadFile = async (path, data, progressHandle) => {
    const url = `${END_POINT_API}${path}`;

    // Prepara i dati
    const formData = new FormData();
    Object.keys(data).forEach((key)=>{
        formData.append(key,data[key]);
    })

    // Header
    let headers = {
        'accept': 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    };
    // aggiunge il token di autenticazione
    let state = store.getState();
    if (state.auth.user && state.auth.user.token) {
        headers['X-Authorization'] = state.auth.user.token;
    }
    let result = null;
    try {
        result = await axios.post(url,formData,{
            headers:headers,
            onUploadProgress: ProgressEvent => {
                if(progressHandle){
                    progressHandle(parseInt(ProgressEvent.loaded / ProgressEvent.total*100.0));
                }
            }
        });
    }
    catch (e) {
        console.error(e);
        throw new ApiException("server_error", "Errore connessione al server");
    }
    return parseReponse(result);
}

const Api =  {
    /** Imposta lo store redux */
    setStore: function (s) {
        store = s;
    },

    post: async (path, data) => {
        return await execute("post", path, data);
    },
    get: async (path, data) => {
        return await execute("get", path, data);
    },
    patch: async (path, data) => {
        return await execute("patch", path, data);
    },
    delete: async (path, data) => {
        return await execute("delete", path, data);
    },
    uploadMedia: async (path, data, progressHandle) => {
        return uploadFile(path, data, progressHandle);
    },

    getToken: () =>{
        let state = store.getState();
        return state.auth.user ?state.auth.user.token:""
    }
};

/** Lista elementi parametri **/
export const FIELDS_PARAM = "fields";
export const FILTER_PARAM = "filter";
export const ORDER_PARAM = "order_by";
export const LIMIT_PARAM = "limit";

export default Api;
