import React from "react";


import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";


export default class ButtonLoading extends React.Component{


    render() {
        const {loading,startIcon,disabled,...other} = this.props;
        const icon = loading? <CircularProgress size={20} /> : startIcon;
        const isDisabled = loading ? true : disabled;
        return <Button {...other} startIcon={icon} disabled={isDisabled}  />
    }
}


