

export const ACTION_LOAD_OBJECT = "LOAD_OBJECT";
export const ACTION_NEW_OBJECT = "NEW_OBJECT";
export const ACTION_LOAD_OBJECT_SUCCESS = "LOAD_OBJECT_SUCCESS";
export const ACTION_LOAD_OBJECT_ERROR = "LOAD_OBJECT_ERROR";
export const ACTION_CLEAR_OBJECT = "CLEAR_OBJECT";

export const ACTION_SET_ATTRIBUTE = "SET_ATTRIBUTE";
export const ACTION_SET_DATA = "SET_DATA";
export const ACTION_VALIDATE_DATA = "VALIDATE_DATA";
export const ACTION_REQUEST_SAVE = "REQUEST_SAVE";

export const ACTION_SAVE_OBJECT = "SAVE_OBJECT";
export const ACTION_SAVE_OBJECT_SUCCESS = "SAVE_OBJECT_SUCCESS";
export const ACTION_SAVE_OBJECT_ERROR = "SAVE_OBJECT_ERROR";

export const ACTION_DELETE_OBJECT = "DELETE_ITEM";
export const ACTION_DELETE_OBJECT_SUCCESS = "DELETE_SUCCESS";
export const ACTION_DELETE_OBJECT_ERROR = "DELETE_ERROR";


//#region OBJECT
export const newObject = (code, data) => {
    return {
        type: ACTION_NEW_OBJECT,
        code,
        data: data ? data : {}
    }
}
/**
 * Carica un oggetto nello stato
 * @param {string} code codice oggetto
 * @param {string} path path api da utilizzare
 */
export const loadObject = (code, path) => {
    return {
        type: ACTION_LOAD_OBJECT,
        code,
        path
    }
};
/**
 * Elimina i dati di un oggetto
 * @param {string} code
 */
export const clearObject = (code) => {
    return {
        type: ACTION_CLEAR_OBJECT,
        code
    }
};

/**
 * Dati oggetto caricati
 * @param {string} code codice oggetto
 * @param {string} data dati
 */
export const loadObjectSuccess = (code, data) => {
    return {
        type: ACTION_LOAD_OBJECT_SUCCESS,
        code,
        data
    }
};

/**
 * Errore nel caricamento dei dati oggetto
 * @param {string} code
 * @param {string} error
 */
export const loadObjectError = (code, error) => {
    return {
        type: ACTION_LOAD_OBJECT_ERROR,
        code,
        error
    }
};
//#endregion


//#region ATTRIBUTE
export const setAttribute = (object, code, value) => {
    return {
        type: ACTION_SET_ATTRIBUTE,
        object: object,
        attribute: code,
        value: value
    }
}

export const setData = (object, data)=>{
    return {
        type: ACTION_SET_DATA,
        object: object,
        data:data
    }
}

export const validateData = (object, isValid) => {
    return {
        type: ACTION_VALIDATE_DATA,
        object: object,
        isValid: isValid
    }
}
//#endregion


//#region SAVE
export const requestSave = (object) => {
    return {
        type: ACTION_REQUEST_SAVE,
        object: object
    }
}

export const saveObject = (code, path, id, successMessage) => {
    return {
        type: ACTION_SAVE_OBJECT,
        object: code,
        path: path,
        id: id,
        successMessage: successMessage
    }
}

export const saveObjectSuccess = (code, data) => {
    return {
        type: ACTION_SAVE_OBJECT_SUCCESS,
        object: code,
        data: data
    }
}

export const saveObjectError = (code, error) => {
    return {
        type: ACTION_SAVE_OBJECT_ERROR,
        object: code,
        error: error
    }
}
//#endregion

//#region DELETE ITEM
export const deleteObject = (object, path, confirmMessage) => {
    return {
        type: ACTION_DELETE_OBJECT,
        path: path,
        object: object,
        confirmMessage: confirmMessage
    }
}

export const deleteConfirmObject = (object, path) => {
    return {
        type: ACTION_DELETE_OBJECT_SUCCESS,
        path: path,
        object: object
    }
}

export const deleteErrorObject = (object, path) => {
    return {
        type: ACTION_DELETE_OBJECT_ERROR,
        path: path,
        object: object
    }
}
//#endregion
