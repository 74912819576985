import { lazy } from "react";

function load(code) {
  switch (code) {
    case "project-report/edit":
      return lazy(() => import("views/project-report/editor"));
    case "project-report/list":
      return lazy(() => import("views/project-report/list"));
    case "project-report/filters/list":
      return lazy(() => import("views/project-report/filters/list"));
    case "project-report/filters/edit":
      return lazy(() => import("views/project-report/filters/editor"));
    default:
      return null;
  }
}
export default load;
