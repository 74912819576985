import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleMenu } from "store/actions/menu";
import { logOut } from "store/actions/auth";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Link } from 'react-router-dom';

import { MenuButton, Logo, Title, WrapTopbar } from "./theme";

// Impostazioni tema
import { AppName, AppLogo } from "components/theme";

class Topbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountMenuEl: null
        };
    }

    handleMenuAccount = event => {
        this.setState({
            accountMenuEl: event.currentTarget
        });
    };

    handleClose = () => {
        this.setState({ accountMenuEl: null });
    };

    handleShowMyProfile = () =>{

        this.setState({ accountMenuEl: null });
    }

    renderAccountMenu = () => {
        const { accountMenuEl } = this.state;
        const open = accountMenuEl !== null;
        return (
            <div>
                <IconButton
                    onClick={this.handleMenuAccount}
                    color="inherit"
                    aria-label="Menu"
                >
                    <AccountCircle />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={accountMenuEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={open}
                    onClose={this.handleClose}
                >
                    <MenuItem onClick={this.handleShowMyProfile}>
                        <Link to={"/user/edit/"+this.props.userId}>Il mio profilo</Link>
                    </MenuItem>
                    <MenuItem onClick={this.props.logOut}>Logout</MenuItem>
                </Menu>
            </div>
        );
    };

    render() {
        const showMenuButton = this.props.routes !== null;
        return (
            <WrapTopbar>
                <AppBar position="fixed" >
                    <Toolbar>
                        {showMenuButton &&
                            <MenuButton>
                                <IconButton
                                    color="inherit"
                                    aria-label="Menu"
                                    onClick={this.props.toggleMenu}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </MenuButton>
                        }
                        <Logo src={AppLogo} />
                        <Title>
                            <Typography variant="h6" color="inherit">
                                {AppName}
                            </Typography>
                        </Title>

                        {this.renderAccountMenu()}
                    </Toolbar>
                </AppBar>
            </WrapTopbar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        routes: state.routing.routes,
        userId: state.auth.user.id
    };
};
const mapDispatchToProps = dispatch => {
    return {
        toggleMenu: bindActionCreators(toggleMenu, dispatch),
        logOut: bindActionCreators(logOut, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Topbar);
