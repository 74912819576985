export const ACTION_SET_TAB = "SET_TAB";


export const setTab = (code,index)=>{
    return {
        type:ACTION_SET_TAB,
        code:code,
        index:index
    }
}
