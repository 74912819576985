import React from "react";

import TextField from "components/forms/text-field";
import Separator from "components/template/separator";
import {Row, RowItem} from "components/theme";
import * as Style from "../login-form/theme";
import Link from "@mui/material/Link";
import Button from "components/button-loading";
import Validations from "components/forms/validations/rules";
import Api from "lib/api";
import {TYPE_ERROR,TYPE_SUCCESS} from "store/actions/notices";


export default class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            error_email:false,
            running: false
        }
    }

    requestPassword = async () => {
        const {addNotice,onClickBack} = this.props;
        const {email} = this.state;
        if(Validations.isEmpty(email) || !Validations.isEmail(email)){
            this.setState({error_email:true});
            return;
        }
        this.setState({running:true, error_email:undefined});
        try{
            await Api.post("/identity/forgot-password",{
                email:email
            });
            addNotice(TYPE_SUCCESS, "Email inviata");
            setTimeout(()=>{
                onClickBack();
            },500);

        }
        catch(e){
            addNotice(TYPE_ERROR,e.message);
        }
        this.setState({
            running:false
        })
    }


    render() {
        return (
            <>
                <Separator size={4}/>
                <TextField
                    fullWidth={true}
                    label={"Email"}
                    variant={"outlined"}
                    value={this.state.email}
                    error={this.state.error_email}
                    errorMessage={"Inserire una mail valida"}
                    onChange={(v) => this.setState({email: v})}
                />
                <Separator size={4}/>
                <Row>
                    <RowItem fill={1} textAlign={"left"}>
                        <Style.ForgotLink>
                            <Link href={"#"} onClick={this.props.onClickBack}>Indietro</Link>
                        </Style.ForgotLink>
                    </RowItem>
                    <RowItem>
                        <Button
                            loading={this.state.running}
                            variant="contained" color="primary" onClick={this.requestPassword} disableElevation>
                            Recupera Password
                        </Button>
                    </RowItem>
                </Row>

            </>
        )
    }
}
