import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { removeNotice } from "store/actions/notices";

import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "components/snackbars";


class Notices extends React.Component {
    static propTypes = {
        notices: PropTypes.array,
        removeNotice: PropTypes.func
    };

    handleClose = id => {
        const { removeNotice } = this.props;
        if (removeNotice) {
            removeNotice(id);
        }
    };

    renderNotice = (notice, key) => {
        return (
            <Snackbar
                key={`n${key}`}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                autoHideDuration={6000}
                open={true}
                onClose={() => {
                    this.handleClose(notice.id);
                }}
            >
                <SnackbarContent
                    onClose={() => {
                        this.handleClose(notice.id);
                    }}
                    variant={notice.type}
                    message={notice.message}
                />
            </Snackbar>
        );
    };

    render() {
        const { notices } = this.props;
        if (notices) {
            return notices.map((n, i) => {
                return this.renderNotice(n, i);
            });
        }
        return null;
    }
}

const mapStateToProps = state => {
    return {
        notices: state.notices.list
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeNotice: bindActionCreators(removeNotice, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Notices);
