import styled from "styled-components";

import {MENU_WIDTH} from "components/theme";

export const WrapMenu = styled.div`
    .os-drawer > div{
        z-index:1099;   
        width:${MENU_WIDTH};
        padding-top:64px;
        transition: transform 0.3s ease-in;
        height:100vh;
    }
    .menu-open .os-drawer > div{       
        transform: translate(0px,0px);
    }
    .menu-close .os-drawer > div{
        transform: translate(-${MENU_WIDTH},0px);
    }
    
  .MuiListItemIcon-root{
    width:56px;
    height: 27px;
     img{
       height: 20px;
       margin-top: 3px;
     }
  }
  
`;

export const Icon = styled.img` 
    width: 30px;
    height: 30px;
`;
