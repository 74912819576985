import styled from "styled-components";

export const RoodDiv = styled.div`
    height: 100vh;
    background-color: #292521;
    background-size: cover;
    background-position: center center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('/assets/images/templates/bg_login.jpg');   
`;



export const LogoImg = styled.img`
    max-height: 66px;
    max-width: 300px;
    height: auto;
    width: auto;
    vertical-align: middle;
    outline: none;
    border: none;
`;

export const FormDiv = styled.div`
    box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.3), 0 0 5px -1px white;
    border-radius:5px;
    mix-blend-mode: normal;
    isolation: isolate;
    width: 400px;
    padding: 1em;
    background-color: rgba(255,255,255,1);
    text-align: center;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position:relative;
`;
