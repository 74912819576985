const ValidationErrors =  {

    isEmail: "Il valore inserito non è una email valida",
    required: "Il campo  è richiesto",
    isNumber: "Il valore inserito non è un numero valido",

    isFloat: "Il valore inserito non è un numero valido",
    isPositive: "Il valore inserito non è un numero valido",

    maxNumber: "Il valore inserito è maggiore di quello possibile",
    minNumber: "Il valore inserito è minore di quello possibile",
    minStringLength: "Il testo inserito è troppo corto",
    maxStringLength: "Il testo inserito è troppo lungo",
    maxFileSize: "Il file caricato è troppo grande",
    allowedExtensions: "Il fil caricato non è supportato",
    isValidDate: "Inserire una data valida"
};
export default ValidationErrors;
