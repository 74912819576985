import { put, call, takeLatest, select } from "redux-saga/effects";
import * as Editor from "store/actions/editor";
import { addNotice, TYPE_SUCCESS, TYPE_ERROR } from "store/actions/notices";
import Api from "lib/api";

/**
 * Elimina un oggetto
 * @param {object} action
 */
function* deleteItem(action) {
    const { path, object, confirmMessage } = action;
    try {
        yield call(Api.delete, path);
        yield put(Editor.deleteConfirmObject(object, path));
        yield put(addNotice(TYPE_SUCCESS, confirmMessage));
    } catch (e) {
        yield put(Editor.deleteErrorObject(object, path));
        yield put(addNotice(TYPE_ERROR, e.message));
    }
}

/**
 * Carica un oggetto
 * @param {*} action
 */
function* loadObject(action) {
    const { path, code } = action;
    try {
        const result = yield call(Api.get, path);
        yield put(Editor.loadObjectSuccess(code, result));
    }
    catch (e) {
        yield put(Editor.loadObjectError(code, e.message));
        yield put(addNotice(TYPE_ERROR, e.message));
    }
}

/**
 * Salva un oggetto
 * @param {*} action
 */
function* saveObject(action) {
    const { path, object, id, successMessage } = action;
    let data = yield select(state => {
        return state.editor[object].data;
    });
    try {
        const result = yield (id === undefined || id === null  || id <=0 ? call(Api.post, path, data) : call(Api.patch, `${path}/${id}`, data));
        // Merge old value with saved value
        data = {
            ...data,
            ...result
        };
        yield put(Editor.saveObjectSuccess(object, data));
        yield put(addNotice(TYPE_SUCCESS, successMessage ? successMessage : "Salvataggio effettutato"));
    }
    catch (e) {
        yield put(Editor.saveObjectError(object, e.message));
        yield put(addNotice(TYPE_ERROR, e.message));
    }
}



//#region HANDLE ACTION
export function* deleteItemSaga() {
    yield takeLatest(Editor.ACTION_DELETE_OBJECT, deleteItem);
}

export function* loadItemSaga() {
    yield takeLatest(Editor.ACTION_LOAD_OBJECT, loadObject);
}

export function* saveItemSaga() {
    yield takeLatest(Editor.ACTION_SAVE_OBJECT, saveObject);
}
//#endregion
