import * as auth from "store/actions/auth";

const initialState = {
    user: {},
    status: auth.STATUS_NOTLOGGED,
    error: false,
    errorMessage: null
};

function appReducer(state = initialState, action) {
    switch (action.type) {
        case auth.ACTION_LOGIN:
            return {
                user: {},
                status: auth.STATUS_LOGGING,
                error: false,
                errorMessage: null
            };

        case auth.ACTION_LOGIN_SUCCESS:
            return {
                user: action.user,
                status: auth.STATUS_LOGGED,
                error: false,
                errorMessage: null
            };
        case auth.ACTION_LOGIN_ERROR:
            return {
                user: action.user,
                status: auth.STATUS_NOTLOGGED,
                error: true,
                errorMessage: action.error
            };
        case auth.ACTION_LOGOUT:
            return {
                user:{},
                status: auth.STATUS_NOTLOGGED,
                error:false,
                errorMessage:null
            };
        case auth.ACTION_CLEAR_ERROR:
            return{
                ...state,
                error:false,
                errorMessage:null
            }
        default:
            return state;
    }
}

export default appReducer;
