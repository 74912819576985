import React from "react";
import PropTypes from "prop-types";

import ValidatorComponent from "components/forms/validations/component";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import OutlinedInput from "@mui/material/OutlinedInput";
import FilledInput from "@mui/material/FilledInput";

export default class TextField extends ValidatorComponent {
  render() {
    const {
      keyInput,
      fullWidth,
      variant,
      label,
      helperText,
      onChange,
      onBlur,
      beforeChange,
      endAdornment,
      startAdornment,
      multiline,
      type,
      rows,
      value,
      margin,
      disabled,
      error,
    } = this.props;
    const { isValid } = this.state;

    const haveError = !isValid || error;
    const inputProps = {
      value: value,
      error: haveError,
      endAdornment: endAdornment,
      startAdornment: startAdornment,
      multiline: multiline,
      rows: rows,
      type: type,
      label: label,
      disabled: disabled,
      onChange: (e) => {
        let v = e.target.value;
        onChange(beforeChange ? beforeChange(v) : v);
      },
      onBlur: (e) => {
        let v = e.target.value;
        onBlur && onBlur(v);
      },
    };

    let inputCmp = <Input {...inputProps} />;
    if (variant === "outlined") inputCmp = <OutlinedInput {...inputProps} />;
    if (variant === "filled") inputCmp = <FilledInput {...inputProps} />;

    return (
      <FormControl
        className={"os-form-control"}
        margin={margin}
        key={keyInput}
        fullWidth={fullWidth}
        variant={variant}
        error={!isValid}
      >
        {label && <InputLabel htmlFor={this.props.code}>{label}</InputLabel>}
        {inputCmp}
        <FormHelperText error={haveError}>
          {(haveError && this.getErrorMessage()) || helperText}
        </FormHelperText>
      </FormControl>
    );
  }
}

TextField.propTypes = {
  error: PropTypes.bool,
  keyInput: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
  label: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  beforeChange: PropTypes.func,
  endAdornment: PropTypes.node,
  startAdornment: PropTypes.node,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  value: PropTypes.any,
  type: PropTypes.string,
  margin: PropTypes.oneOf(["dense", "none", "normal"]),
  errorMessage: PropTypes.string,
  validators: PropTypes.array,
};
