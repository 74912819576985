import styled from "styled-components";

export const RootDiv = styled.div`
    display:block;
    position:relative;
    min-height:100vh;
    width:100%;
    background-color:#F6F6F6;
`;


export const LoadingContent = styled.div`
    display:block;
    width:100%;
    height:100%;
    padding:40px;
    padding-top:90px;
`;
