/**
 * Configurazione store redux
 */
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

// Lista saga
import watchSaga from "./saga";
// Reduser app
import createReducer from "./reducers";

import { collectionStateFilter } from "./reducers/collection";

export default function configureStore(initialState = {}) {
    // Redux saga
    const sagaMiddleware = createSagaMiddleware();
    // Middleware
    const middlewares = [
        sagaMiddleware,
        // Log status
        createLogger({
            collapsed: true
        })
    ];
    const enhancers = [applyMiddleware(...middlewares)];

    /** Persist configuration */
    const persistConfig = {
        key: "root",
        storage: storage,
        whitelist: ["auth", "collection","tab"],
        transforms: [collectionStateFilter]
    };
    const pReducer = persistReducer(persistConfig, createReducer());


    // If Redux DevTools Extension is installed use it, otherwise use Redux compose
    /* eslint-disable no-underscore-dangle */
    const composeEnhancers =
        process.env.NODE_ENV !== "production" &&
            typeof window === "object" &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            : compose;
    /* eslint-enable */

    /* Crea lo store */
    const store = createStore(
        pReducer,
        initialState,
        composeEnhancers(...enhancers)
    );

    // Avvia redux-saga
    sagaMiddleware.run(watchSaga);


    const persistor = persistStore(store);

    return {
        store,
        persistor
    };
}
