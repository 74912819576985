import React from "react";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
// THEME
import CssBaseline from "@mui/material/CssBaseline";
import {ThemeProvider} from "@mui/material/styles";
import {STATUS_LOGGED} from "store/actions/auth";
import {setDeviceResolution} from "store/actions/device";

import {AppTheme, DefaultTitle} from "components/theme";

import LoginPage from "views/login";
import AdminArea from "views/admin";

import 'typeface-roboto';
import Notices from "components/template/notices";


class Root extends React.Component {

    isLogged = () => {
        return this.props.loginStatus === STATUS_LOGGED;
    };

    componentDidMount = () => {
        window.addEventListener("resize", () => {
            this.props.setDeviceResolution();
        });
    }

    render() {
        return (
            <ThemeProvider theme={AppTheme}>
                <Helmet titleTemplate={DefaultTitle}/>
                <CssBaseline/>
                {this.isLogged() ? <AdminArea/> : <LoginPage/>}
                <Notices/>
            </ThemeProvider>
        );
    }
}


const mapStateToProps = state => {
    return {
        loginStatus: state.auth.status
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setDeviceResolution: bindActionCreators(setDeviceResolution, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
