import {ACTION_SET_DEVICE_RESOLUTION,BREAKPOINT} from "store/actions/device";

function getResolution() {
    return {
        type: getDeviceType(window.innerWidth),
        resolution: {
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight
        }
    };
}

/** CALCOLA TIPO DI DEVICE ATTRAVERSO BREAKPOINT */
function getDeviceType(windowInnerWidth) {
    let device = null;
    let br;
    for(br in BREAKPOINT){
        let dim = BREAKPOINT[br];
        if(windowInnerWidth >= dim){
            device = br;
        }
    }
    return device;
}

const initialState = {
   ...getResolution()
};

function appReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_SET_DEVICE_RESOLUTION:
            return {
                ...getResolution()
            };
        default:
            return state;
    }
}

export default appReducer;
