import { put, call, takeLatest } from "redux-saga/effects";
import * as Auth from "store/actions/auth";
import User from "lib/user";

export default function* watcherSaga() {
    yield takeLatest(Auth.ACTION_LOGIN, loginSaga);
}

function* loginSaga(action) {
    try {
        const { username, password } = action;
        const userData = yield call(User.login, username, password);
        yield put({ type: Auth.ACTION_LOGIN_SUCCESS, user:userData });
    } catch (error) {
        yield put({ type: Auth.ACTION_LOGIN_ERROR, error: error.message });
    }
}
