import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme } from "@mui/material/styles";

import ErrorIconUI from "@mui/icons-material/Error";
import WarningIconUI from "@mui/icons-material/Warning";

// Nome applicazione
export const AppName = "Overside Business";

export const DefaultTitle = "%s - Overside";

export const AppLogo = "/assets/logo_mini.svg";

// DIMENSIONI

export const SPACE = "30px";
export const SMALL_SPACE = "15px";

export const MENU_WIDTH = "250px";
export const EDITOR_MAX_WIDTH = "1024px";

// Paginazione
export const DEFAULT_PAGINATION_SIZE = 40;
export const PAGINAZION_SIZES = [20, 40, 60];

// Colori
export const BORDER_COLOR = "#ccc";

export const MAIN_BORDER_COLOR = "rgba(0, 0, 0, 0.12)";

export const ERROR_COLOR = "#f44336";

export const WARNING_COLOR = "#ff9800";

export const SUCCESS_COLOR = "#4caf50";

// Configurazioni tema MATERIAL UI
export const AppTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1800,
      xxxl: 2100,
    },
  },
  palette: {
    primary: { main: "#212121", dark: "#000000", light: "#484848" },
    secondary: { main: "#ff9800", dark: "#c66900", light: "#ffc947" },
  },
  typography: {
    fontSize: 13,
    h6: {
      fontWeight: 400,
      letterSpacing: "normal",
    },
  },
  overrides: {
    MuiTab: {
      root: {
        minWidth: "100px",
      },
    },
    MuiPaper: {
      root: {
        border: `1px solid ${MAIN_BORDER_COLOR}`,
      },
    },
    MuiFormLabel: {
      root: {
        transform: `translate(0px -9px)`,
      },
    },
  },
});

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${(props) =>
    props.verticalAlign ? props.verticalAlign : "center"}
  justify-content: ${(props) => (props.align ? props.align : "flex-start")}
  padding: ${(props) => (props.padding ? props.padding : "0px")}
`;

export const RowItem = styled.div`
  flex-grow: ${(props) => (props.fill ? "1" : "0")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "inherit")};
`;

export const ErrorMessage = styled.div`
  margin: ${(props) => (props.margin ? props.margin : "20px")};
  padding: 10px;
  display: block;
  text-align: center;
  color: #fff;
  font-size: 16px;
  background-color: #f44336;
  border-radius: 5px;
  border-width: 0px;
`;

export const ActionTopbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0px;

  h3 {
    flex-grow: 1;
    text-align: left;
    padding: 0px 10px;
  }

  button {
    margin-left: 10px;
  }
`;

export const Info = styled.div`
  font-size: ${(props) => (props.size ? props.size : "15px")};
  color: #444;
  flex-grow: 1;

  span:last-child {
    font-weight: bold;
  }
`;

export const EditorContent = styled.div`
  max-width: ${EDITOR_MAX_WIDTH};
  padding: 0px ${SPACE};
  margin: 0px auto;
`;

export const FixedHeadContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;

  .header,
  .content-section {
    max-width: ${EDITOR_MAX_WIDTH};
    width: 98%;
    margin: 0px auto;
  }

  .content-section {
    padding-top: 20px;
  }

  .content {
    flex: 1;
    display: block;
    overflow: auto;
  }
`;

export const FormItemSeparator = styled.div`
  height: 10px;
  width: 100%;
`;

export const SmallCircularProgress = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;
`;

export const ErrorIcon = styled(ErrorIconUI)`
  color: #f44336;
`;

export const WarningIcon = styled(WarningIconUI)`
  color: #ff9800;
`;
