import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom'

import { isBigger } from "store/actions/device";
import { setOpenMenu } from "store/actions/menu";

import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from '@mui/material/Divider';


import { WrapMenu, Icon } from "./theme";

class Menu extends React.Component {

    componentDidUpdate(prevProps) {
        /** CAMBIA LA MODALITA' DEL MENU IN BASE ALLA RISOLUZIONE DELLO SCHERMO */
        const oldType = this.getMenuType(prevProps.device);
        const newType = this.getMenuType(this.props.device);
        // Nessun cambiamento
        if (oldType === newType) return;
        // Chiude/ Apre il menu quando si cambia da una risoluzione ad un altra
        if (newType === "persistent" && !this.props.isOpen) {
            this.props.setOpenMenu(true);
        }
        if (newType === "temporary" && this.props.isOpen) {
            this.props.setOpenMenu(false);
        }
    }

    getMenuType = device => {
        return isBigger(device, "md") ? "persistent" : "temporary";
    };


    createMenuItem = (key,icon,label,url)=>{
        const isButton = url !== undefined;
        return  (
            <ListItem button={isButton} key={key} component={Link} to={url}>
                <ListItemIcon>
                    <Icon src={`/assets/icons/${icon}.svg`} alt={icon}/>
                </ListItemIcon>
                <ListItemText secondary={label} />
            </ListItem>
        );
    }

    renderRoute = (route,key) =>{
        if(!route.items)
            return [];

        const keyGroup = `${key}-${route.id}`;
        // Parent
        let items = [];
        route.items.forEach((child,i)=>{
            if(child.in_menu){
                items.push(this.createMenuItem(`${keyGroup}-${i}`,child.icon,child.label,child.url));
            }
        });
        return items;
    }



    renderRoutes = () => {
        const { routes } = this.props;
        if (routes === null) return null;

        let items = [];
        routes.forEach((item, i) => {
            items = items.concat(this.renderRoute(item,i));
            if(i<routes.length-1){
                items.push(<Divider key={`${i}-divider`}/>);
            }
        });
        return items;
    };

    render() {
        const { isOpen, device } = this.props;
        let wrapMenuClass = `os-wrap-menu ${
            isOpen ? "menu-open" : "menu-close"
        }`;
        return (
            <WrapMenu>
                <div className={wrapMenuClass}>
                    <Drawer
                        className="os-drawer"
                        variant={this.getMenuType(device)}
                        anchor="left"
                        open={isOpen}
                        onClose={() => {
                            this.props.setOpenMenu(false);
                        }}
                    >
                        {this.renderRoutes()}
                    </Drawer>
                </div>
            </WrapMenu>
        );
    }
}

const mapStateToProps = state => {
    return {
        isOpen: state.menu.isOpen,
        device: state.device.type,
        routes: state.routing.routes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setOpenMenu: bindActionCreators(setOpenMenu, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Menu);
