export const ACTION_REQUEST_ROUTE = "REQUEST_ROUTE";
export const ACTION_REQUEST_ROUTE_SUCCESS = "REQUEST_ROUTE_SUCCESS";
export const ACTION_REQUEST_ROUTE_FAIL = "ACTION_REQUEST_ROUTE_FAIL";

export const requestRoute = () => {
    return {
        type: ACTION_REQUEST_ROUTE
    };
};
export const setRoute = routes => {
    return {
        type: ACTION_REQUEST_ROUTE_SUCCESS,
        routes: routes
    };
};

export const errorLoadRoute = error => {
    return {
        type: ACTION_REQUEST_ROUTE_FAIL,
        error: error
    };
};
