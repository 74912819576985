import { lazy } from "react";

function load(code) {
  switch (code) {
    case "invoice/list-reminder":
      return lazy(() => import("views/document/list/reminder"));
    case "invoice/list":
      return lazy(() => import("views/document/list/invoice"));
    case "expenses/list":
      return lazy(() => import("views/document/list/expenses"));
    case "document/editor":
      return lazy(() => import("views/document/editor"));
    case "invoice/report":
      return lazy(() => import("views/document/report"));
    default:
      return null;
  }
}
export default load;
