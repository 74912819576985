import React from "react";

import Validator from "./validator";
import ErrorMessage from "./error-message";


export default class ValidatorComponent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            isValid: true,
            errorMessage: null
        }
    }

    componentDidMount() {
        const { formId } = this.props;
        if (formId) {
            Validator.addItem(formId, this);
        }
    }

    componentWillUnmount() {
        const { formId } = this.props;
        if (formId) {
            Validator.removeItem(formId, this);
        }
    }


    componentDidUpdate(prec) {
        if (this.props.value !== prec.value && !this.state.isValid) {
            this.validate();
        }
    }

    validate = () => {
        try {
            this.isValid();
            if (!this.state.isValid) {
                this.setState({
                    isValid: true,
                    errorMessage: null
                });
            }
            return true;
        }
        catch (e) {
            if (this.state.isValid || this.state.errorMessage !== e.message) {
                this.setState({
                    isValid: false,
                    errorMessage: e.message
                })
            }
            return false;
        }
    }

    /**
     * Verifica se l'elemento è valido
     */
    isValid = () => {
        const { value } = this.props;
        const validators = this.getValidations();
        if (validators && validators.length > 0) {
            for (let i = 0; i < validators.length; i++) {
                const rule = validators[i];
                if (!Validator.validateRule(rule, value)) {
                    const e = ErrorMessage[rule] !== undefined ? ErrorMessage[rule] : "Il valore inserito non è valido";
                    throw Error(e);
                }
            }
        }
        return true;
    }

    getValidations = () =>{
        return this.props.validators;
    }

    makeValid = () =>{
        this.setState({
            isValid:true
        });
    }

    getErrorMessage = () => {

        return this.props.errorMessage ? this.props.errorMessage : this.state.errorMessage;
    }
}