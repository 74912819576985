
/**
 * Verifica se l'oggetto è vuoto
 * @param {*} o
 */
export const isEmptyObject = o => {
    return o === undefined || o === null || Object.keys(o).length === 0;
}

/**
 * Verifica se la variabile è vuota
 * @param {*} o
 */
export const isEmpty = o => {
    return o === undefined || o === null || o === "";
}

/**
 * Restituisce i dati dell'editor  con codice passato in ingresso
 * @param {*} state
 * @param {*} objectCode
 */
export const getStateEditor = (state, objectCode) => {
    return state.editor[objectCode] ? state.editor[objectCode] : null;
}

export const getStateEditorData = (state, objectCode) => {
    return state.editor[objectCode] && state.editor[objectCode].data ? state.editor[objectCode].data : {};
}

/**
 * Totale elementi array del campo passato
 * @param {*} items
 * @param {*} field
 */
export const getTotal = (items, field) => {
    if (items && items.length > 0) {

        let t = 0;
        items.forEach((i) => t += (i[field] ? getFloat(i[field]) : 0));
        return t;
    }
    return 0;
}
/**
 * Restituisce il valore
 * @param {*} value
 */
export const getFloat = (value) => {
    return isNaN(value) || value === "" ? 0 : parseFloat(value);
}

/**
 * Search element in array
 * @param {*} list
 * @param {*} key
 * @param {*} value
 */
export const find = (list,key, value) =>{
    for(let i=0;i<list.length;i++){
        if(list[i][key]===value){
            return list[i];
        }
    }
    return null;
}

/**
 * Formatta un prezzo
 * @param value
 * @returns {string}
 */
export const formatPrice = (value) =>{
    return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value);
}

export const formatSeconds = (seconds) =>{
    const hour = Math.floor(seconds/3600);
    const minutes = Math.floor((seconds- (hour*3600))/60);
    if(hour===0 && minutes===0){
        return '0';
    }
    return (hour>0?`${hour}h`:'')+' '+(minutes>0?`${minutes}m`:'');
}

export const getAverage = (list)=>{
    if(list.length===0) return 0;
    return list.reduce((t,i)=>t+getFloat(i),0)/getFloat(list.length);
}

export const MONTH_NAMES = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"]



export const debounce = (func, timeout = 300)=>{
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}
