import styled from "styled-components";

export const MenuButton = styled.div`
    margin-left: -12px;
    margin-right: 20px;
`;

export const Title = styled.div`
    flex-grow: 1;
`;

export const Logo = styled.img`
    height: 40px;
    width: 40px;
    display: block;
    border-radius: 100%;
    background-color: #fff;
    margin-left: -12px;
    margin-right: 10px;
`;

export const WrapTopbar = styled.div`
    .MuiPaper-elevation4-16 {
        box-shadow: none;
    }
`;
