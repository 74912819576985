import moment from "moment";

const isExisty = function isExisty(value) {
    return value !== null && value !== undefined;
};

const _isEmpty = function _isEmpty(value) {
    if (value instanceof Array) {
        return value.length === 0;
    }
    return value === '' || !isExisty(value);
};

const isEmptyTrimed = function isEmptyTrimed(value) {
    if (typeof value === 'string') {
        return value.trim() === '';
    }
    return true;
};

const validations = {
    matchRegexp: function matchRegexp(value, regexp) {
        var validationRegexp = regexp instanceof RegExp ? regexp : new RegExp(regexp);
        return _isEmpty(value) || validationRegexp.test(value);
    },

    // eslint-disable-next-line
    isEmail: function isEmail(value) {
        return validations.matchRegexp(value,  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    },

    isEmpty: function isEmpty(value) {
        return _isEmpty(value);
    },

    required: function required(value) {
        return !_isEmpty(value);
    },

    trim: function trim(value) {
        return !isEmptyTrimed(value);
    },

    isNumber: function isNumber(value) {
        return validations.matchRegexp(value, /^-?[0-9]\d*(\d+)?$/i);
    },

    isFloat: function isFloat(value) {
        return validations.matchRegexp(value, /^(?:-?[1-9]\d*|-?0)?(?:\.\d+)?$/i);
    },

    isPositive: function isPositive(value) {
        if (isExisty(value)) {
            return (validations.isNumber(value) || validations.isFloat(value)) && value >= 0;
        }
        return true;
    },

    maxNumber: function maxNumber(value, max) {
        return _isEmpty(value) || parseInt(value, 10) <= parseInt(max, 10);
    },

    minNumber: function minNumber(value, min) {
        return _isEmpty(value) || parseInt(value, 10) >= parseInt(min, 10);
    },

    maxFloat: function maxFloat(value, max) {
        return _isEmpty(value) || parseFloat(value) <= parseFloat(max);
    },

    minFloat: function minFloat(value, min) {
        return _isEmpty(value) || parseFloat(value) >= parseFloat(min);
    },

    isString: function isString(value) {
        return !_isEmpty(value) || typeof value === 'string' || value instanceof String;
    },
    minStringLength: function minStringLength(value, length) {
        return validations.isString(value) && value.length >= length;
    },
    maxStringLength: function maxStringLength(value, length) {
        return validations.isString(value) && value.length <= length;
    },

    // eslint-disable-next-line no-undef
    isFile: function isFile(value) {
        return value instanceof File;
    },
    maxFileSize: function maxFileSize(value, max) {
        return validations.isFile(value) && value.size <= parseInt(max, 10);
    },
    allowedExtensions: function allowedExtensions(value, fileTypes) {
        return validations.isFile(value) && fileTypes.split(',').indexOf(value.type) !== -1;
    },
    isValidDate: function isValidDate(value) {
        try {
            if (validations.isEmpty(value)) return true;
            const d = moment(value);
            return d.isValid();
        }
        catch (e) {
            return false;
        }
    }
};

export default validations;
