import { all } from "redux-saga/effects";
import authSaga from "./auth";
import routeSaga from "./routing";
import {
    loadListSaga,
    orderListSaga,
    paginationListSaga,
    filterListSaga,
    reloadListSaga
} from "./collection";
import { deleteItemSaga, loadItemSaga, saveItemSaga } from "./editor";

export default function* watchAll() {
    yield all([
        authSaga(),
        routeSaga(),
        loadListSaga(),
        reloadListSaga(),
        orderListSaga(),
        paginationListSaga(),
        filterListSaga(),
        deleteItemSaga(),
        loadItemSaga(),
        saveItemSaga()
    ]);
}
