import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import Topbar from "components/template/topbar";
import Head from "components/template/head";
import MainMenu from "components/template/menu";
import MainContent from "components/template/content";
import { requestRoute } from "store/actions/routing"

import CircularProgress from '@mui/material/CircularProgress';

import { RootDiv, LoadingContent } from "./theme";
import { ErrorMessage } from "components/theme";


import { WaitingComponent } from "views/viewFactory";

class AdminArea extends React.Component {

    key = 0;

    constructor(props) {
        super(props);
        // Carica le rotte nel caso in cui non fossero ancora presenti
        if (props.routing.routes === null) {
            this.props.requestRoute();
        }
    }



    /**
     * Restituisce il primo url valido (Una rotta in cui è impostato il componente)
     */
    findFirstValidLink = (routes) => {
        let k, ki;
        for (k in routes) {
            for (ki in routes[k].items) {
                let r = routes[k].items[ki];
                if (r.component)
                    return r.url;
            }
        }
        return null;
    }

    /**
     * Crea una rotta
     */
    createRoute = (routeData,url) => {
        if (routeData.component === undefined) return null;
        return <Route key={`${(this.key++)}`} path={url} component={WaitingComponent(routeData.component)} />
    }

    renderRoutes = () => {
        const { routing: { routes, loading, error } } = this.props;

        // Caricamento rotte
        if (loading) {
            return <LoadingContent><CircularProgress /></LoadingContent>
        }

        // errore nel caricamento
        if (error) {
            return <LoadingContent><ErrorMessage>Impossibile caricare le rotte</ErrorMessage></LoadingContent>
        }
        // Nessuna rotta
        if (routes === null) {
            return null;
        }

        // Crea le rotte
        let list = [];
        routes.forEach((rGroup) => {
            rGroup.items.forEach((itm) => {
                const urls = typeof itm.url==="string" ? [itm.url]:itm.url;
                urls.forEach((url)=>{
                    let route = this.createRoute(itm,url);
                    if (route) {
                        list.push(route);
                    }
                });

            });
        });
        let fUrl = this.findFirstValidLink(routes);
        if (fUrl) {
            // Redirect finale alla prima pagina
            list.push(<Route key={"notfound"} render={() => <Redirect to={fUrl} />} />);
        }
        return (
            <React.Fragment>
                <MainMenu />
                <MainContent>
                    <Switch>
                        {list}
                    </Switch>
                </MainContent >
            </React.Fragment >


        );
    }



    render() {
        // TODO redux menu
        return (
            <RootDiv>
                <Head />
                <Topbar />
                {this.renderRoutes()}
            </RootDiv >
        )
    }
}


const mapStateToProps = state => {
    return {
        routing: state.routing
    };
};
const mapDispatchToProps = dispatch => {
    return {
        requestRoute: bindActionCreators(requestRoute, dispatch)
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminArea));
