import styled from "styled-components";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {AppTheme} from "components/theme";

export const ForgotLink = styled.div`
   a,a:visited,a:active{
     text-decoration: none;
     color:${AppTheme.palette.primary.light};
   }
`

export const ActionDiv = styled.div`
    display:block;
    width:100%;
    padding:15px 0px;
    text-align:right;
`;

export const AccountIcon = styled(AccountCircle)`
    margin-right:12px;
`;

export const LoaderWrap = styled.div`
    position:absolute;
    top:0px;
    left:0px;
    border-radius:5px;
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content: center;
    background-color:rgba(255,255,255,0.5);
`;
