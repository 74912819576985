import * as editor from "store/actions/editor";

const initialState = {

};

function appReducer(state = initialState, action) {
    switch (action.type) {
        //#region LOAD OBJECT
        case editor.ACTION_NEW_OBJECT:
            return {
                ...state,
                [action.code]: {
                    requestSave: false,
                    data: action.data,
                    error: false,
                    errorMessage: null,
                    loading: false,
                    deleting: false,
                    saving: false
                }
            }

        case editor.ACTION_LOAD_OBJECT:
            return {
                ...state,
                [action.code]: {
                    requestSave: false,
                    data: null,
                    error: false,
                    errorMessage: null,
                    loading: true,
                    deleting: false,
                    saving: false
                }
            };

        case editor.ACTION_LOAD_OBJECT_ERROR:
            return {
                ...state,
                [action.code]: {
                    ...state[action.code],
                    data: null,
                    loading: false,
                    error: true,
                    errorMessage: action.error,
                    typeError:'load',
                }
            };

        case editor.ACTION_LOAD_OBJECT_SUCCESS:
            return {
                ...state,
                [action.code]: {
                    ...state[action.code],
                    data: action.data,
                    loading: false,
                }
            };
        //#endregion

        //#region ATTRIBUTE
        case editor.ACTION_SET_ATTRIBUTE:
            return {
                ...state,
                [action.object]: {
                    ...state[action.object],
                    data: {
                        ...state[action.object].data,
                        [action.attribute]: action.value
                    }
                }
            };
        case editor.ACTION_SET_DATA:
            return {
                ...state,
                [action.object]: {
                    ...state[action.object],
                    data: {
                        ...state[action.object].data,
                        ...action.data
                    }
                }
            };
        //#endregion

        //#region SAVE
        case editor.ACTION_REQUEST_SAVE:
            return {
                ...state,
                [action.object]: {
                    ...state[action.object],
                    requestSave: true
                }
            };
        case editor.ACTION_SAVE_OBJECT:
            return {
                ...state,
                [action.object]: {
                    ...state[action.object],
                    requestSave: false,
                    saving: true,
                    error: false,
                    errorMessage: null,
                }
            };
        case editor.ACTION_SAVE_OBJECT_SUCCESS:
            return {
                ...state,
                [action.object]: {
                    ...state[action.object],
                    data: action.data,
                    requestSave: false,
                    saving: false,
                    error: false,
                    errorMessage: null
                }
            };
        case editor.ACTION_SAVE_OBJECT_ERROR:
            return {
                ...state,
                [action.object]: {
                    ...state[action.object],
                    saving: false,
                    requestSave: false,
                    error: true,
                    errorMessage: action.error,
                    typeError:'save',
                }
            };
        //#endregion

        //#region  DELETE
        case editor.ACTION_DELETE_OBJECT:
            return {
                ...state,
                [action.object]: {
                    ...state[action.object],
                    deleting: true,
                    deletePath: action.path,
                    error: false,
                    errorMessage: null,
                }
            };

        case editor.ACTION_DELETE_OBJECT_SUCCESS:
            return {
                ...state,
                [action.object]: {
                    ...state[action.object],
                    data: null,
                    deleting: false,
                    deletePath: null,
                    error: false,
                    errorMessage: null,
                }
            };

        case editor.ACTION_DELETE_OBJECT_ERROR:
            return {
                ...state,
                [action.object]: {
                    ...state[action.object],
                    deleting: false,
                    deletePath: null,
                    error: true,
                    typeError:'delete',
                }
            };
        //#endregion

        case editor.ACTION_CLEAR_OBJECT:
            let newState = [];
            let code;
            for (code in state) {
                if (action.code !== code) {
                    newState[code] = state[code];
                }
            }
            return newState;

        default:
            return state;
    }
}

export default appReducer;
