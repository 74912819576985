
// URL api
export const END_POINT_API = process.env.REACT_APP_END_POINT_API;
// Asset url
export const MEDIA_URL = process.env.REACT_APP_END_POINT_API;


export const TINY_APIKEY ="n06y8sjdodtxvfblekfto8o9rcmdicf9czko2a5qojr9wbmi";


export const MAX_FILE_SIZE_UPLOAD = 100000000
