import { lazy } from "react";


function load(code){
    switch (code) {
        case "cashflow/dashboard":
            return lazy(() => import("views/cashflow/dashboard"));
        case "cashflow/categories":
            return lazy(() => import("views/cashflow/categories"));
        default:
            return null;
    }
}
export default load;
