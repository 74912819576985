import React from "react";
import { connect } from "react-redux";
import { isBigger } from "store/actions/device";
import { WrapContent } from "./theme";

class MainContent extends React.Component {
    render() {
        const { menuOpen,device } = this.props;
        const withMenu   = isBigger(device,"md")? menuOpen: false;
        let contentClass = `os-wrapcontent ${(withMenu?"with-menu":"fullwidth")}`;
        return (
           <WrapContent>
                <div className={contentClass}>
                    {this.props.children}
                </div>
           </WrapContent>
        );
    }
}


const mapStateToProps = state => {
    return {
        menuOpen: state.menu.isOpen,
        device: state.device.type
    };
};

export default connect(mapStateToProps)(MainContent);
