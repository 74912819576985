import { lazy } from "react";


function load(code){
    switch (code) {
        case "configuration/edit":
            return lazy(() => import("views/configuration/edit"));
        default:
            return null;
    }
}
export default load;