import { put, call, takeLatest } from "redux-saga/effects";
import * as Routing from "store/actions/routing";
import Admin from "lib/admin";


export default function* watcherSaga() {
    yield takeLatest(Routing.ACTION_REQUEST_ROUTE, loadRoute);
}

function* loadRoute() {
    try {
        const routes = yield call(Admin.loadRoute);
        yield put(Routing.setRoute(routes));
    } catch (error) {
        console.error(error);
        yield put(Routing.errorLoadRoute(error.message));
    }
}
