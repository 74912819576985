import * as route from "store/actions/routing";

const initialState = {
    routes: null,
    loading:false,
    error:false,
    errorMessage:null
};

function appReducer(state = initialState, action) {
    switch (action.type) {
        case route.ACTION_REQUEST_ROUTE:
            return {
                ...state,
                loading:true,
                error:false,
                errorMessage:null
            };

        case route.ACTION_REQUEST_ROUTE_SUCCESS:
            return {
                ...state,
                routes: action.routes,
                loading: false,
            };
        case route.ACTION_REQUEST_ROUTE_FAIL:
            return {
                routes: null,
                loading:false,
                error:true,
                errorMessage:action.error
            };
        default:
            return state;
    }
}

export default appReducer;
