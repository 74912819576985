import React from "react";



const Separator =  ({size,isHorizontal})=> {
    if(isHorizontal){
        const width = isNaN(size)?5:size*5;
        return <div style={{ width: `${width}px`}}>&nbsp;</div>
    }
    const heigth = isNaN(size)?5:size*5;
    return <div style={{ height: `${heigth}px`, clear: "both"}}>&nbsp;</div>
}
export default Separator;
