export const ACTION_LOAD_LIST_OBJECTS = "LOAD_LIST_OBJECTS";
export const ACTION_LOAD_LIST_OBJECTS_SUCCESS = "LOAD_LIST_OBJECTS_SUCCESS";
export const ACTION_LOAD_LIST_OBJECTS_ERROR = "LOAD_LIST_OBJECTS_ERROR";

export const ACTION_SET_LIST_ORDER = "SET_LIST_ORDER";
export const ACTION_SET_LIST_PAGINATION = "SET_LIST_PAGINATION";
export const ACTION_SET_LIST_FILTER = "SET_LIST_FILTER";
export const ACTION_RELOAD_LIST = "RELOAD_LIST";
export const ACTION_CLEAR_LIST = "CLEAR_LIST";


export const loadList = (code, path, fields, filters, order, pagination) => {
    return {
        type: ACTION_LOAD_LIST_OBJECTS,
        path: path,
        code: code,
        fields: fields,
        filters: filters,
        order: order,
        pagination
    };
};


export const setListOrder = (code, path, column, direction) => {
    return {
        type: ACTION_SET_LIST_ORDER,
        code,
        path,
        column,
        direction
    }
}

export const setFilters = (code, path, filters) => {
    return {
        type: ACTION_SET_LIST_FILTER,
        code,
        path,
        filters
    }
}


export const setPagination = (code, path, page, length) => {
    return {
        type: ACTION_SET_LIST_PAGINATION,
        code,
        path,
        page,
        length
    }
}


export const reloadList = (code, path) => {
    return {
        type: ACTION_RELOAD_LIST,
        path: path,
        code: code
    };
}




export const successLoadList = (code, data) => {
    return {
        type: ACTION_LOAD_LIST_OBJECTS_SUCCESS,
        data: data,
        code: code
    };
};

export const errorLoadList = (code, error) => {
    return {
        type: ACTION_LOAD_LIST_OBJECTS_ERROR,
        error: error,
        code: code
    };
};

export const clearList = (code) => {
    return {
        type: ACTION_CLEAR_LIST,
        code: code
    };
}