import { lazy } from "react";


function load(code){
    switch (code) {
        case "report/list":
            return lazy(() => import("views/report/list"));
        case "report/quote":
            return lazy(() => import("views/report/quote"));
        case "report/tag-quotes":
            return lazy(() => import("views/report/tag-quotes"));
        default:
            return null;
    }
}
export default load;