import styled from "styled-components";
import {MENU_WIDTH} from "components/theme";

export const WrapContent = styled.div`
    padding-top:64px;
    .os-wrapcontent{
        -webkit-transition: padding-left 0.2s ease-in;
        -moz-transition: padding-left 0.2s ease-in;
        -ms-transition: padding-left 0.2s ease-in;
        -o-transition: padding-left 0.2s ease-in;
        transition: padding-left 0.2s ease-in;
        min-height: calc(100vh - 64px);
    }

    .os-wrapcontent.with-menu {
        padding-left:${MENU_WIDTH};
    }
    .os-wrapcontent.fullwidth{
        padding-left:0px;
    }
    
    .os-wrapcontent > form{
        display:block;
        height:100%;
    }
`;
