

import DefaultRule from "./rules";

class Validator {

    /** Lista regole attive */
    rules = {};

    /** Lista form attivi */
    form = {}


    /**
     * Valida i componenti di un fomr
     */
    validate = (formId) => {
        if (this.form[formId]) {
            let isValid = true;
            this.form[formId].forEach((component) => {
                if (!component.validate()) {
                    isValid = false;
                }
            })
            return isValid;
        }
        return false;
    }

    /**
     * Aggiunge un elemento al form
     */
    addItem = (formId, component) => {
        if (this.form[formId] === undefined) {
            this.form[formId] = [];
        }
        if (this.form[formId].indexOf(component) <= 0) {
            this.form[formId].push(component);
        }
    }

    /**
     * Rimuove un elemnto dal form
     */
    removeItem = (formId, component) => {
        if (this.form[formId]) {
            let index = this.form[formId].indexOf(component);
            if (index >= 0) {
                this.form[formId].splice(index, 1);
            }
        }
    }
    /**
     * Rimuove un elemnto dal form
     */
    clearForm = (formId) => {
        this.form[formId] = undefined;
    }

    /**
     * Restituisce la regola con il codice di errore passato in ingresso
     */
    validateRule = (rule, value) => {
        const rulePart = rule.split(":");
        const ruleFunc = this.getRule(rulePart[0]);
        if (ruleFunc) {
            return ruleFunc(value, rulePart[1]);
        }
        return null;
    }

    getRule = (rule) => {
        if (DefaultRule[rule] !== undefined) return DefaultRule[rule];
        if (this.rules[rule] !== undefined) return this.rules[rule];
        return null;
    }

    /**
     * Aggiunge una regola di validazione 
     */
    addValidationRule = (rule, handle) => {
        this.rules[rule] = handle;
    }

    /**
     * Rimuove la regola di validazione
     */
    removeValidationRule = (rule) => {
        this.rules[rule] = undefined;
    }
}



const validator = new Validator();
export default validator;