import auth from "./auth";
import menu from "./menu";
import device from "./device";
import routing from "./routing";
import collection from "./collection";
import editor from "./editor";
import notices from "./notices";
import tab from "./tab";


import {combineReducers}  from "redux";
/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
    return combineReducers({
      auth,
      menu,
      device,
      routing,
      collection,
      editor,
      notices,
      tab,
      ...asyncReducers,
    });
  }
