import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import green from '@mui/material/colors/green';
import amber from '@mui/material/colors/amber';
import IconButton from '@mui/material/IconButton';
import SnackbarContentUI from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import { withStyles } from '@mui/styles';



const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};


const styles1 = theme => ({
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(2),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  });


class SnackbarContent extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired
    };

    style={}


    render() {
        const {
            classes,
            className,
            message,
            onClose,
            variant,
            ...other
        } = this.props;
        const Icon = variantIcon[variant];
        return (
            <SnackbarContentUI
                className={classNames(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={classNames(
                                classes.icon,
                                classes.iconVariant
                            )}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>
                ]}
                {...other}
            />
        );
    }
}


export default withStyles(styles1)(SnackbarContent);
