import { lazy } from "react";


function load(code){
    switch (code) {
        case "employee/list":
            return lazy(() => import("views/employee/list"));
        case "employee/edit":
            return lazy(() => import("views/employee/editor"));
        case "employee/time-log":
            return lazy(()=>import("views/employee/time-log"));
        default:
            return null;
    }
}
export default load;
