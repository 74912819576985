export const ACTION_TOOGLE_MENU = "TOOGLE_MENU";
export const ACTION_CHANGE_OPEN = "CHANGE_OPEN";

export const toggleMenu = () => {
    return {
        type: ACTION_TOOGLE_MENU
    };
};

export const setOpenMenu = (open) =>{
    return {
        type: ACTION_TOOGLE_MENU,
        open:open
    };
}