import * as menu from "store/actions/menu";

const initialState = {
    isOpen: window.innerWidth > 960
};

function appReducer(state = initialState, action) {
    switch (action.type) {
        case menu.ACTION_TOOGLE_MENU:
            return {
                isOpen: !state.isOpen
            };
        case menu.ACTION_CHANGE_OPEN:
            return {
                isOpen: action.open
            };
        default:
            return state;
    }
}

export default appReducer;
