export const ACTION_SET_DEVICE_RESOLUTION = "SET_DEVICE_RESOLUTION";

let orderBreakpoint =['xs','sm','md','lg','xl'];

// Lista breakpoint
export const BREAKPOINT = {
    'xs':0,
    'sm':600,
    'md':960,
    'lg':1280,
    'xl':1920
};

/** Verifica se il breakpint br è maggiore di bs */
export const isBigger = (br,bs) =>{
    let ibr  = orderBreakpoint.indexOf(bs);
    if(ibr <= 0) return false;
    for(let i=ibr;i<orderBreakpoint.length;i++){
        if(orderBreakpoint[i] === br) return true;
    }
    return false;
}

/** Verifica se il breakpoint è più pccolo di bs */
export const isSmallest = (br,bs) =>{
    let ibs  = orderBreakpoint.indexOf(br);
    if(ibs <= 0) return false;
    for(let i=ibs;i<orderBreakpoint.length;i++){
        if(orderBreakpoint[i] === bs) return true;
    }
    return false;
}

export const setDeviceResolution = () => {
    return {
        type: ACTION_SET_DEVICE_RESOLUTION
    };
};
