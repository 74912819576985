import { lazy } from "react";


function load(code){
    switch (code) {
        case "quote/list":
            return lazy(() => import("views/quote/list"));
        case "quote/editor":
            return lazy(() => import("views/quote/editor"));
        default:
            return null;
    }
}
export default load;