import { lazy } from "react";


function load(code){
    switch (code) {
        case "car-refund/cars/list":
            return lazy(() => import("views/car-refunds/cars/list"));
        case "car-refund/cars/editor":
            return lazy(() => import("views/car-refunds/cars/editor"));
        case "car-refund/car-cost/editor":
            return lazy(() => import("views/car-refunds/car-cost/editor"));
        case "car-refund/refund/list":
            return lazy(() => import("views/car-refunds/refund/list"));
        case "car-refund/refund/editor":
            return lazy(() => import("views/car-refunds/refund/editor"));
        default:
            return null;
    }
}
export default load;
