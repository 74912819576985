export const STATUS_NOTLOGGED = "not_logged";
export const STATUS_LOGGED = "logged";
export const STATUS_LOGGING = "logging";

export const ACTION_LOGIN = "LOGIN";
export const ACTION_LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const ACTION_LOGIN_ERROR = "LOGIN_ERROR";
export const ACTION_LOGOUT = "LOGOUT";
export const ACTION_LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const ACTION_CLEAR_ERROR = "CLEAR_ERROR";

export const requestLogin = (username, password) => {
    return {
        type: ACTION_LOGIN,
        username,
        password
    };
};

export const logOut = () => {
    return {
        type: ACTION_LOGOUT
    };
};

export const clearError = () =>{
    return {
        type:ACTION_CLEAR_ERROR
    }
}
