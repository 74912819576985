import { put, call, takeLatest, select } from "redux-saga/effects";
import * as Objects from "store/actions/collection";
import { addNotice, TYPE_ERROR } from "store/actions/notices";
import Api from "lib/api";

/**
 * Restituisce i dati dello stato della lista
 * identificata dal path
 */
function* getListData(code) {
    return yield select(state => {
        const data = state.collection[code] ? state.collection[code] : null
        return {
            fields: data ? data.fields : [],
            filters: data ? data.filters : {},
            order: data ? data.order : null,
            pagination: data ? data.pagination : null
        };
    });
}

/**
 * Carica la lista oggetti
 * @param {Object} action
 */
function* loadObjects(action) {
    const { code, path, fields, filters, order, pagination } = action;
    try {
        const params = {
            page: pagination.page,
            limit: pagination.length,
            notnull: true
        };
        // Aggiunti filtri colonne da visualizzare
        if (fields && fields.length > 0) {
            params.fields = fields.join(",");
        }

        // set order
        if (order) {
            params.order_by = order.column;
            params.order_dir = order.direction;
        }
        if (filters) {
            let list =  Object.keys(filters).map((k)=>filters[k]);
            params.filter = JSON.stringify(list);
        }
        const data = yield call(Api.get, path, params);
        yield put(Objects.successLoadList(code, data));
    } catch (e) {
        yield put(Objects.errorLoadList(code, e.message));
        yield put(addNotice(TYPE_ERROR, e.message));
    }
}

/**
 * Ricarica la collezione
 * @param {*} action
 */
function* reloadList(action) {
    const { code, path } = action;
    const pathData = yield getListData(code);
    yield put(
        Objects.loadList(
            code,
            path,
            pathData.fields,
            pathData.filters,
            pathData.order,
            pathData.pagination
        )
    );
}

/**
 * Cambia l'ordinamento della lista
 */
function* changeOrderList(action) {
    const { code, path, column, direction } = action;
    // Carica i filtri già presenti nello store
    const listData = yield getListData(code);

    // Avvia il caricamento della lista oggetti con i nuovi filtri
    yield put(
        Objects.loadList(
            code,
            path,
            listData.fields,
            listData.filters,
            { column, direction },
            listData.pagination
        )
    );
}

/**
 * Cambia la paginazione della lista
 */
function* changePaginationList(action) {
    const { code, path, page, length } = action;
    // Carica i dati della lista
    const listData = yield getListData(code);
    // Avvia il caricamento della lista oggetti con la nuova paginazione
    yield put(
        Objects.loadList(
            code,
            path,
            listData.fields,
            listData.filters,
            listData.order, {
            page,
            length
        })
    );
}

function* changeFilters(action) {
    const { code, path, filters } = action;
    const pathData = yield getListData(code);
    pathData.pagination.page=0;
    yield put(Objects.loadList(code, path,  pathData.fields, filters, pathData.order, pathData.pagination));
}



//#region SAGA
export function* loadListSaga() {
    yield takeLatest(Objects.ACTION_LOAD_LIST_OBJECTS, loadObjects);
}

export function* orderListSaga() {
    yield takeLatest(Objects.ACTION_SET_LIST_ORDER, changeOrderList);
}

export function* paginationListSaga() {
    yield takeLatest(Objects.ACTION_SET_LIST_PAGINATION, changePaginationList);
}

export function* filterListSaga() {
    yield takeLatest(Objects.ACTION_SET_LIST_FILTER, changeFilters);
}

export function* reloadListSaga() {
    yield takeLatest(Objects.ACTION_RELOAD_LIST, reloadList);
}

//#endregion
