import * as tab from "store/actions/tab";

const initialState = {
};

function appReducer(state = initialState, action) {
    switch (action.type) {
        case tab.ACTION_SET_TAB:
            return {
                [action.code]: action.index
            };
        default:
            return state;
    }
}

export default appReducer;
