import {ACTION_ADD_NOTICES,ACTION_REMOVE_NOTICES} from "store/actions/notices";

const initialState = {
    list: []
};

function appReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_ADD_NOTICES:
            return {
                list:[
                    ...state.list,
                    action.notice
                ]
            };
        case ACTION_REMOVE_NOTICES:
            let notices = [];
            state.list.forEach((n)=>{
                if(n.id === action.id ){
                    return;
                }
                notices.push(n);
            });
            return {
                list:notices
            }
        default:
            return state;
    }
}

export default appReducer;
