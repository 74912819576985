import api from "lib/api";

export const API_PATH = "/api/v1/user";

/**
 *  Utente loggato
 */
class User{

    login = async (username,password)=>{
        const result = await api.post("/identity/connect/token",{
            username,
            password
        });
        return result;

    }
}

let currentUser = new User();
export default currentUser;