import React, {Component} from "react";
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react';

//date picker supporo
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { BrowserRouter } from "react-router-dom";
// Register chartjs elements
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';

import Root from "views";
import createStore from './store';
import Api from "lib/api";



ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);


// Crea lo store dell'app
const StoreConfig = createStore({});

// Imposta lo store nelle api
Api.setStore(StoreConfig.store);

class App extends Component {
    render() {
        return (
            <Provider store={StoreConfig.store}>
                <PersistGate persistor={StoreConfig.persistor}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <BrowserRouter>
                            <Root/>
                        </BrowserRouter>
                    </LocalizationProvider>
                </PersistGate>
            </Provider>
        );
    }
}

export default App;
