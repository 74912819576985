import React from "react";

import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "components/snackbars";
import LoginForm from "components/page/login-form";
import ForgotForm from "components/page/forgot-password";
import {requestLogin, clearError, STATUS_LOGGING} from "store/actions/auth";
import {addNotice} from "store/actions/notices"

import * as Style from "./style";

class LoginPage extends React.Component {
    static propTypes = {
        loginStatus: PropTypes.string,
        loginError: PropTypes.bool,
        loginErrorMessage: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            showError: false,
            showForgotPassword: false
        };
    }

    static getDerivedStateFromProps(props) {
        const {loginError} = props;
        if (loginError) {
            return {
                showError: true
            };
        }
        return null;
    }

    componentDidUpdate() {
        if (!this.state.showError && this.props.loginError) {
            this.setState({
                showError: true
            });
        }
    }

    handleCloseError = () => {
        this.props.clearError();
        this.setState({
            showError: false
        });
    }

    handleSubmit = data => {
        this.props.login(data.username, data.password);
    };

    render() {
        const {loginStatus, loginErrorMessage} = this.props;
        const {showPassword} = this.state;
        const errorMessage = (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                autoHideDuration={6000}
                open={this.state.showError}
                onClose={this.handleCloseError}
            >
                <SnackbarContent variant="error" message={loginErrorMessage} onClose={this.handleCloseError}/>
            </Snackbar>
        );

        return (
            <Style.RoodDiv>
                <Style.FormDiv>
                    <Style.LogoImg src="/assets/images/templates/overside_logo_login.png"/>
                    {!showPassword && (
                        <LoginForm
                            onShowPassword={() => this.setState({showPassword: true})}
                            onSubmit={this.handleSubmit}
                            loading={loginStatus === STATUS_LOGGING}
                        />)
                    }
                    {showPassword && <ForgotForm addNotice={this.props.addNotice} onClickBack={()=>this.setState({showPassword:false})} />}
                </Style.FormDiv>
                {errorMessage}
            </Style.RoodDiv>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginStatus: state.auth.status,
        loginError: state.auth.error,
        loginErrorMessage: state.auth.errorMessage
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: bindActionCreators(requestLogin, dispatch),
        clearError: bindActionCreators(clearError, dispatch),
        addNotice: bindActionCreators(addNotice,dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);
