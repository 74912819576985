import React, { lazy, Suspense } from "react";
import UserComponent from "views/user";
import CompanyComponent from "views/company";
import QuoteComponent from "views/quote";
import Document from "views/document";
import DocumentPayment from "views/document-payment";
import OrderPayment from "views/order-payment";
import Configurations from "views/configuration";
import Employee from "views/employee";
import BankTransaction from "views/bank-transaction";
import Reports from "views/report";
import ProjectReport from "views/project-report"
import Reward from "views/reward"
import CarRefund from "views/car-refunds";
import CashFlow from "views/cashflow";

/** Loader view principali app */
function DefaultComponent(code) {
    switch (code) {
        case "Dashboard":
            return lazy(() => import("views/dashboard"));
        default:
            return null;
    }
}

/** Lista loadeer views */
const listLoader = [
    DefaultComponent,
    UserComponent,
    CompanyComponent,
    QuoteComponent,
    Document,
    DocumentPayment,
    OrderPayment,
    Configurations,
    Employee,
    BankTransaction,
    Reports,
    ProjectReport,
    Reward,
    CarRefund,
    CashFlow
];

/** Carica il componente con il codice passato in ingresso */
function FindComponent(code) {
    for (let i = 0; i < listLoader.length; i++) {
        let cmp = listLoader[i](code);
        if (cmp !== null)
            return cmp;
    }
    return null;
}


export function WaitingComponent(code) {
    return props => {
        let Component = FindComponent(code);
        if (Component === null) return null;
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <Component {...props} />
            </Suspense>
        );
    };
}
