import { lazy } from "react";


function load(code){
    switch (code) {
        case "company/list":
            return lazy(() => import("views/company/list"));
        case "company/editor":
            return lazy(() => import("views/company/editor"));
        default:
            return null;
    }
}
export default load;
